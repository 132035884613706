function Logo() {
    return (
        <a className="flex text-white no-underline items-center" href="/">
            <div className="">
                <svg className={`w-3 h-3 md:h-4 md:w-4 lg:h-5 lg:w-5 xl:w-6 xl:h-6 2xl:h-7 2xl:h-7`} viewBox="0 0 53 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M52.9009 23.7647C52.2062 19.4969 47.2435 17.3133 43.2737 19.0999L39.105 20.9855C35.1348 22.7721 30.6688 22.7721 26.6987 20.9855L22.53 19.0999C18.5598 17.3133 13.5974 19.4969 12.9028 23.7647C12.8033 24.0622 12.8033 24.4593 12.8033 24.8563C12.8033 25.1539 12.8033 25.5509 12.9028 25.9479C13.5974 30.2157 18.5601 32.3993 22.53 30.6127L26.6987 28.727C30.6688 26.9405 35.1348 26.9405 39.105 28.727L43.2737 30.6127C47.2438 32.3993 52.2062 30.2157 52.9009 25.9479C52.9999 25.6503 52.9999 25.2533 52.9999 24.8563C52.9999 24.5587 52.9999 24.1617 52.9009 23.7647V23.7647Z"
                        fill="white"/>
                    <path
                        d="M9.72698 12.1524L13.8957 10.2667C17.8659 8.48012 22.3318 8.48012 26.302 10.2667L30.4707 12.1524C34.4409 13.939 39.4033 11.7554 40.0979 7.48757C40.197 7.18998 40.197 6.79296 40.197 6.39594C40.197 6.09834 40.197 5.70132 40.0979 5.3043C39.4033 1.03652 34.4405 -1.14707 30.4707 0.639507L26.302 2.52518C22.3318 4.31176 17.8659 4.31176 13.8957 2.52518L9.72698 0.639507C5.7568 -1.14707 0.794403 0.937104 0.0997869 5.3043C0.000366142 5.6019 0.000366211 5.99892 0.000366211 6.39594C0.000366211 6.69387 0.000366142 7.09055 0.0997869 7.48757C0.794403 11.7554 5.7568 13.939 9.72698 12.1524V12.1524Z"
                        fill="white"/>
                    <path
                        d="M30.4703 37.362L26.3016 39.2477C22.3314 41.0343 17.8655 41.0343 13.8953 39.2477L9.72661 37.362C5.75643 35.5755 0.794036 37.7591 0.0994207 42.0268C-6.93672e-08 42.3244 0 42.7215 0 43.1185C0 43.4164 -6.93672e-08 43.8131 0.0994207 44.2101C0.794036 48.4779 5.75676 50.6615 9.72661 48.8749L13.8953 46.9892C17.8655 45.2027 22.3314 45.2027 26.3016 46.9892L30.4703 48.8749C34.4405 50.6615 39.4029 48.4779 40.0975 44.2101C40.1966 43.9122 40.1966 43.5155 40.1966 43.1185C40.1966 42.8209 40.1966 42.4239 40.0975 42.0268C39.4029 37.7587 34.4402 35.5755 30.4703 37.362Z"
                        fill="white"/>
                </svg>
            </div>
            <h6 className="font-bold text-purple-500 ml-1">Devs</h6>
            <h6 className="font-light">Decide</h6>
        </a>
    );
}

export default Logo;